import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { DESC_ICON } from "../assets/icons/IconList";


export default function OfferingsContent(props) {

  return (
    <div className="offeringsContentContainer">
  <Button
          href="https://saiconline.sharepoint.us/teams/EnterpriseITDIFPortal/Shared%20Documents/Forms/AllItems.aspx"
          target="_blank"
          style={{
            fontSize: "18px",
            textAlign: "center",
            width: "325px",
            height: "50px",
            padding: "10px",
            color: "#ffffff",
            background: "#006bb6",
          }}
        >
          Document Repository
        </Button>
    </div>
  )
}
