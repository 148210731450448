import ServiceImg1 from "../assets/images/services/EIT-Service-1.jpeg"
import ServiceImg2 from "../assets/images/services/EIT-Service-2.jpg"
import ServiceImg3 from "../assets/images/services/EIT-Service-3.jpeg"

export const data = [
  {
    title: "Enterprise IT / Managed Services",
    description:
    "Aligned to business activities and mission requirements, SAIC’s EIT and IT managed services deliver service processes and functions that improve operational effectiveness, efficiency, and consistency, leveraging people, processes and product partners to provide transparent performance and financial stability.  Our managed services can be delivered via multiple operating models, whether it be dedicated, shared, or a hybrid approach, and are supported by the flexible U-Centric service management framework to ensure stable performance and an optimized customer experience.These services can utilize different contract/pricing structures, including fixed unit price, T&M, or a combination thereof, and be delivered via multiple operating structures – shared, dedicated, or as a fixed, commercial offering.",
      image: ServiceImg1
 
  },
  {
    title: "Digital Workspace",
      description:
      "Digital Workspace enables mission success by delivering transformative, outcome-based enterprise IT solutions using the U-Centric® integrated technology and process framework designed to optimize the user experience, drive operational efficiency, and unlock deep service intelligence.",
      introBullet: "The following delivery areas are available through Digital Workspace:",
      bulletOne: "End User Services: End user services provide secure, personalized support that dynamically adjusts to user needs and optimizes productivity. ",
      bulletTwo: "Enterprise Service Management: Enterprise Service Management transforms and optimizes critical IT service management platforms.",
      bulletThree: "Managed NOC & SOC: Our IT Operations services ensure availability, performance, and security of customer IT infrastructure through full NOC and SOC offerings.",
      image: ServiceImg2
  
  },
  {
    title: "Digital Transformation",
    description:
      "Digital Transformation (DX) is the use of digital technology to enable significant improvement in the realization of an organization’s objectives that could not feasibly have been achieved by non-digital means.  Digital transformation is achieved by digitizing, robotizing, and other forms of automation that enable organizations to do things differently, or to do different things. In short, SAIC defines DX as Business Models that embrace Technology as a Critical Enabler. SAIC has a DX ½ day workshop and assessment services to help organizations evaluate their DX readiness and develop their DX Vision, Strategies, and Roadmaps. SAIC’s U-Centric service management framework enables DX for our customers as part of our comprehensive service capabilities. DX positions SAIC as a trusted advisor and partner with our customers.",
      image: ServiceImg3
  
  }
]
