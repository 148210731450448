

export const data = [
  {
    title: "Dataiku",
    desc: "Dataiku has been named a leader two years running in the Gartner Magic Quadrant for Data Science and Machine-Learning Platforms. SAIC partnered with Dataiku to underpin our Tenjin offering for a collaborative low code to full code data science platform.",
    category: "Strategic"
  }


]
