import React from "react"
import { 
  Typography,
   Grid,
   ButtonGroup,
   Button
  } from "@material-ui/core"
import HOME_BANNER from "../assets/images/EIT_HOME_BANNER.jpg"
import AboutAIJson from "../data/aboutAI.json"

export default function AboutAI() {
  const [state] = React.useState({
    dataValues: AboutAIJson,
  });

  return (
    <div className="aboutAIContainer" >
      <Grid container xs={12} className="banner">
        <Grid item xs={12} sm={12}>
        
        <Typography className="aboutTitle fadePanel">
         
         <i className="line">
            Enterprise IT Managed Services
         </i>
     
     </Typography>
    
        <img  alt="Home Banner" src={ HOME_BANNER} style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12} className="homeButtons"  >
        <Grid item xs={12}  sm={4} md={4} lg={3} className="marginCenter" >

            <ButtonGroup variant="contained">
              <Button 
              variant="contained"
              target="_blank"
              href=" https://saiconline.sharepoint.us/teams/EnterpriseITDIFPortal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FEnterpriseITDIFPortal%2FShared%20Documents%2F101%20Business%20Documents%2FU%2DCentric%20Service%20Management%20Offering%20Guide%2Epdf&viewid=f94c5bd4%2D7416%2D4f73%2D8f44%2D6c2f4d61ee03&parent=%2Fteams%2FEnterpriseITDIFPortal%2FShared%20Documents%2F101%20Business%20Documents"
              >
                Offering Guide 
                </Button>
            
              <Button
                variant="contained"
                target="_blank"
                href="https://saiconline.sharepoint.us/:p:/r/teams/EnterpriseITDIFPortal/_layouts/15/Doc.aspx?sourcedoc=%7BFF584573-0DD4-47E5-9F02-4E63E061FF2E%7D&file=101%20Customer%20Brief_U-Centric%20Service%20Management%20Framework.pptx&action=edit&mobileredirect=true"
              >
                Customer Brief
              </Button>
            </ButtonGroup>
             
          </Grid>
      </Grid>

      <div className="spacer" />
      <div className="spacer" />
      <div className="spacer" />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="introduction"
      >
        <Grid className="marginCenter" item xs={10} >
       
            <Typography variant="h2" className="subTitle">
             
                 Enterprise IT Managed Services
         
           </Typography>

        </Grid>

        <div className="spacer" />
        
        {state.dataValues.map((item) => {
          return (
            <Grid className="marginCenter" item xs={10} sm={10} md={10} lg={10} >
              <Typography  >
             {
              item.intro
             }
              </Typography>
            </Grid>
          )
        })}
        <div className="spacer" />
        <div className="spacer" />
        <div className="dividerShape" />

      </Grid>
    </div>
  );
}
