import React from 'react'

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import InfoIcon from '@material-ui/icons/Info'
import AspectRatioIcon from '@material-ui/icons/AspectRatio'
import RefreshIcon from '@material-ui/icons/Refresh'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import HomeIcon from '@material-ui/icons/Home'
import VisibilityIcon from '@material-ui/icons/Visibility'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LightBulb from '@material-ui/icons/EmojiObjects'
import MenuIcon from '@material-ui/icons/Menu'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import SettiingsIcon from '@material-ui/icons/Settings'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import CloseIcon from '@material-ui/icons/Close'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import BuildIcon from '@material-ui/icons/Build'

const homeIcon = () => {
  return <HomeIcon style={{color: '#006bb6', fontSize: '27px'}} />
}

const buildIcon = (props) => {
  return <BuildIcon style={{fontSize: props.iconSize, color: props.iconColor}} />
}


const announcementIcon = (props) => {
  return <AnnouncementIcon style={{color: props.iconColor, fontSize: props.iconSize}} />
}

const closeIcon = () => {
  return <CloseIcon style={{color: '#006bb6', fontSize: '27px'}} />
}

const menuIcon = (props) => {
  return <MenuIcon style={{fontSize: '30px', color: '#ffffff'}} />
}

const dataIcon = (props) => {
  return <DataUsageIcon style={{fontSize: '60px', color: '#CCC'}} />
}

const menuUpArrowIcon = (props) => {
  return <KeyboardArrowUpIcon style={{fontSize: props.iconSize, color: props.iconColor}} />
}

const emailIcon = (props) => {
  return <EmailIcon style={{fontSize: props.iconSize, color: props.iconColor}} />
}

const gearIcon = (props) => {
  return <SettiingsIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const phoneIcon = (props) => {
  return <PhoneIcon style={{fontSize: '30px', color: '#006bb6'}} />
}


const arrowUpIcon = () => {
  return <ArrowUpwardIcon />
}

const checkCircleIcon= (props) => {
  return <CheckCircleIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const expandLessIcon = (props) => {
  return <ExpandLessIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const expandMoreIcon = (props) => {
  return <ExpandMoreIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const descIcon= (props) => {
  return <DescriptionIcon style={{fontSize: '30px', color: '#006bb6'}} />
}

const deleteIcon = (props) => {
  return <DeleteIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const chartIcon = (props) => {
  return <InsertChartIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const personIcon = (props) => {
  return <AccountCircleIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const moreInfoIcon = (props) => {
  return <InfoIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const aspectRatio = (props) => {
  return <AspectRatioIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const reuseIcon = (props) => {
  return <RefreshIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const videoIcon = (props) => {
  return <LiveTvIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const viewIcon = (props) => {
  return <VisibilityIcon style={{fontSize: '20', color: '#a4a4a4'}}  />
}

const unlockIcon = (props) => {
  return <LockOpenIcon style={{fontSize: props.iconSize, color: props.iconColor}}  />
}

const lightBulbIcon = (props) => {
  return <LightBulb style={{fontSize: props.iconSize, color: props.iconColor}}  />
}


export const Icon = ({ component, style, ...props }) => (
  component({
    style: {
      width: '33px',
      height: '33px',
      borderRadius: '10px',
      textAlign: 'center',
      padding: '5px',
      boxShadow: '#819dadff 1px 2px 4px',
      ...style
    },
    ...props
  })
)


export const HOME_ICON = props => Icon({ component: homeIcon, ...props })
export const MENU_ICON = props => Icon({ component: menuIcon, ...props })
export const MENU_UP_ARROW = props => Icon({ component: menuUpArrowIcon, ...props })
export const ARROW_UP_ICON = props => Icon({ component: arrowUpIcon, ...props })
export const CHECK_CIRCLE_ICON = props => Icon({ component: checkCircleIcon, ...props })
export const DESC_ICON = props => Icon({ component: descIcon, ...props })
export const DELETE_ICON = props => Icon({ component: deleteIcon, ...props })
export const CHART_ICON = props => Icon({ component: chartIcon, ...props })
export const PERSON_ICON = props => Icon({ component: personIcon, ...props })
export const MORE_INFO_ICON = props => Icon({ component: moreInfoIcon, ...props })
export const APSECT_RATIO_ICON = props => Icon({ component: aspectRatio, ...props })
export const RESUSE_ICON = props => Icon({ component: reuseIcon, ...props })
export const VIDEO_ICON = props => Icon({ component: videoIcon, ...props })
export const VIEW_ICON = props => Icon({ component: viewIcon, ...props })
export const UNLOCK_ICON = props => Icon({ component: unlockIcon, ...props })
export const LIGHTBULB_ICON = props => Icon({ component: lightBulbIcon, ...props })
export const EXPAND_MORE_ICON = props => Icon({ component: expandMoreIcon, ...props })
export const EXPAND_LESS_ICON = props => Icon({ component: expandLessIcon, ...props })
export const EMAIL_ICON = props => Icon({ component: emailIcon, ...props })
export const PHONE_ICON = props => Icon({ component: phoneIcon, ...props })
export const GEAR_ICON = props => Icon({ component: gearIcon, ...props })
export const DATA_ICON = props => Icon({ component: dataIcon, ...props })
export const CLOSE_ICON = props => Icon({ component: closeIcon, ...props })
export const BUILD_ICON = props => Icon({ component: buildIcon, ...props })
export const ANNOUNCEMENT_ICON = props => Icon({ component: announcementIcon, ...props })

export default function getAllIcons () {
  return(
    <div>Icon List</div>
  )
}
