import React from "react";
import { 
  Grid, 
  Typography 
} from "@material-ui/core";
import { data } from "../data/offeringSubs.js";

export default function OfferingsSubs() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="approachAIContainer">
      <Grid container xs={11} sm={11} md={10} lg={11} spacing={3} className="container" >
        {state.dataValues.map((item) => {
          return (
            <Grid item xs={12} sm={12} md={4} lg={4} className="columns">
               <a
                className="linkBox"
              
                alt={item.title}
              >
              <img src={item.image} />
                <div className="spacer" />
                <Typography className="title">{item.title}</Typography>
               </a>
              <div className="spacer" />
              <Typography className="desc">{item.description}</Typography>
            
              {
                item.title === "Digital Workspace" ? <span>
                  <br/>
                  <br/>
                  <Typography style={{textAlign: 'left'}}><b>{item.introBullet}</b></Typography>
                  <ul style={{listStyle: "none", textAlign: 'left', marginLeft: '-40px'}}>
                    <li>
                    <Typography className="desc"> {item.bulletOne}</Typography>
                    </li>
                    <br/>
                    <li>
                    <Typography className="desc">{item.bulletTwo}</Typography>
                    </li>
                    <br/>
                    <li>
                    <Typography className="desc">{item.bulletThree}</Typography>
                    </li>
                  </ul>
                </span>: ''
              }
            </Grid>
          )
        })}
      </Grid>
    </div>
  );
}
