import React from "react"
import { Grid, Typography } from "@material-ui/core"
import AwsExportConfig from "./aws-exports"
import { Auth, Amplify } from "aws-amplify"
import PartnersContent from "./content/partners"

Amplify.configure(AwsExportConfig());

class InvestmentsPage extends React.Component {
  componentDidMount() {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        console.log("Pages: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    };

    getUser();
  }

  render() {
    return (
      <main className="investmentsPageContainer">
        <Grid container xs={12} sm={12} className="fadePanel">
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" className="pageTitleSection">
              Partner Relations
            </Typography>
            <div className="spacer" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <PartnersContent />
          </Grid>
        </Grid>
      </main>
    );
  }
}

export default InvestmentsPage;
