import * as React from 'react';
import {
     Menu,
     MenuItem
  } from '@material-ui/core'

export default function DropdownMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <div className="dropDownMenuContainer" >
      <a
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{color: '#fff',fontSize: '18px'}}
      >
        {props.menuItem}
      </a>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem >
            <a href={props.itemOneLink} style={{color: '#006bb6', textDecoration: 'none'}} >
                {props.itemOne}
            </a>
        </MenuItem>
        <MenuItem>
            <a href={props.itemTwoLink}  style={{color: '#006bb6', textDecoration: 'none'}} >
            {props.itemTwo}
            </a>
        </MenuItem>
        <MenuItem>
            <a href={props.itemThreeLink}  style={{color: '#006bb6', textDecoration: 'none'}} >
             {props.itemThree}
             </a>
        </MenuItem>
      </Menu>
    </div>
  );
}
