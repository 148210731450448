import BERNIE_PROFILE from "../assets/images/profile/Bernie_Profile.png"
import PAUL_PROFILE from "../assets/images/profile/Paul_Profile.png"

export const data = [
  {
    name: "Bernie Tomasky",
    title: "VP Enterprise IT / Managed Services",
    email: "bernard.e.tomasky@saic.com",
    desc: "Bernie has over 35 years of experience developing and delivering Enterprise IT solutions across Federal, Defense, and commercial customers. He has deep expertise in developing managed service operating models, technologies, and implementation approaches.  He is skilled in all facets of managed service delivery, including the development of managed service platform capabilities, short and long term IT strategy planning, IT operations and engineering, application development/support, project management, IT / Business workflow automation, and performance reporting and benchmarking.",
    image: BERNIE_PROFILE,
  },
  {
    name: "Paul Harker",
    title: "Director – Digital Transformation",
    email: "paul.n.harker@saic.com",
    desc: "Paul is a trusted strategic leader with over 30 years’ experience developing vision, strategy, roadmaps, practices and services, and leading global IT organizations, PMOs and cloud migration programs. Paul creates holistic and innovative solutions that transform organizations and provide forward thinking business capabilities that result in extreme efficiencies and new business opportunities.",
    image: PAUL_PROFILE,
  },

]
