import React from "react"
import { Typography } from "@material-ui/core"
import AccordionComponent from "../components/accordion"

export default function faqContent(props) {
  return (
    <div className="faqContentContainer">
      <div className="titleLine" />
      <Typography className="category">General</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "general") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
                answer2={data.answer2}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

     

    </div>
  )
}
