import React from "react"
import { 
   Grid,
   Typography
   } from "@material-ui/core"
import {PartnersCard} from '../components/cards'
import {data} from "../data/partners.js"


export default function InvestmentsContent() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="partnersContainer" style={{height: '75vh'}} >
     
    </div>
  );
}
